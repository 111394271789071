/* Light Mode */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  max-width: 350px;
  width: 100%;
  margin: 20px;
  text-align: center;
}

.card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.card p {
  font-size: 16px;
  line-height: 1.4;
  color: #555;
  margin-bottom: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FF7F50;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ff6c37;
}

/* Dark Mode */
.dark-mode .card-container {
  background-color: #333333;
}

.dark-mode .card {
  background-color: #444;
  color: #fff;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.dark-mode .card h2 {
  color: #fff;
}

.dark-mode .card p {
  color: #ccc;
}

.dark-mode .button {
  background-color: #2e2e2e;
  color: #fff;
}

.dark-mode .button:hover {
  background-color: #1f1f1f;
}
